import { ref } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
// import selectValues from '@/use/selects/values'
import toastHandler from '@/use/toastHandler'

export default () => {
  const data = ref({})
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const editForm = formHandler()
  const currentNodes = ref([])
  const availableNodes = ref([])
  const showAdd = ref(false)
  const selectedNodes = ref([])
  const users = ref([])
  const usersAvailable = ref([])
  const showAssignUser = ref(false)
  const selectedUsers = ref([])
  const nodeTypes = ref([])
  const showAddNodeType = ref(false)
  const addNodeTypeForm = formHandler()
  const showEditNodeType = ref(false)
  const editNodeTypeForm = formHandler()
  const logs = ref([])
  const useToast = toastHandler()

  const mapper = {
    Electricity: 'electricity',
    Gas: 'gas',
    Water: 'water',
    Heat: 'heat',
    HeatCost: 'heat_cost',
  }

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)

    useApi.requestV2('get', `v1/organisations/${payload}`).then((response) => {
      const standardFields = ['object_id', 'name', 'date_created', 'date_edited']
      data.value = response.data
      const editData = {}

      standardFields.forEach((field) => {
        editData[field] = response.data[field]
      })

      const settings = response.data.settings

      if ('demo' in settings) {
        editData.demo = settings.demo
      } else {
        editData.demo = false
      }

      if ('prices' in settings) {
        editData.prices = settings.prices
      } else {
        editData.prices = false
      }

      let utilities = []

      if ('utilities' in settings) {
        utilities = settings.utilities
      }

      Object.keys(mapper).forEach((key) => {
        if (utilities.includes(key)) {
          editData[mapper[key]] = true
        }
      })

      editForm.create({
        type: 'edit',
        fields: detailFields.value,
        data: editData,
      })

      getNodes(payload)
      getUsers(payload)
      getNodeTypes(payload)
      getLogs(payload)

      loadHandler.setLoadingState('get_details', false)
    })
  }

  function getNodeTypes(payload) {
    loadHandler.setLoadingState('get_node_types', true)

    useApi.requestV2('get', `v1/organisations/${payload}/node_types`).then((response) => {
      nodeTypes.value = response.data
      loadHandler.setLoadingState('get_node_types', false)
    })
  }

  function getUsers(payload) {
    loadHandler.setLoadingState('get_users', true)

    useApi.requestV2('get', `v1/organisations/${payload}/users`).then((response) => {
      users.value = response.data
      loadHandler.setLoadingState('get_users', false)
    })
  }

  function getNodes(organisation_id) {
    useApi.requestV2('get', `v1/organisations/${organisation_id}/nodes`).then((response) => {
      currentNodes.value = response.data
    })
  }

  function getLogs(organisation_id) {
    loadHandler.setLoadingState('list_logs', true)
    useApi
      .requestV2('get', `v1/organisations/${organisation_id}/logs`)
      .then((response) => {
        logs.value = response.data
        loadHandler.setLoadingState('list_logs', false)
      })
      .catch(() => {
        loadHandler.setLoadingState('list_logs', false)
      })
  }

  function edit() {
    loadHandler.setLoadingState('edit_node', true)
    const formData = editForm.data.value

    const parsedPayload = {
      name: formData.name,
      settings: {
        utilities: [],
      },
    }

    if (formData.demo === true || formData.demo === false) {
      parsedPayload.settings['demo'] = formData.demo
    }

    if (formData.prices === true || formData.prices === false) {
      parsedPayload.settings['prices'] = formData.prices
    }

    for (const [key, value] of Object.entries(mapper)) {
      if (formData[value] === true) {
        parsedPayload.settings['utilities'].push(key)
      }
    }

    const objectId = editForm.data.value.object_id
    useApi.requestV2('patch', `v1/organisations/${objectId}`, parsedPayload).then(() => {
      loadHandler.setLoadingState('edit_node', false)
    })
  }

  function openAdd() {
    showAdd.value = true
    const organisation_id = data.value.object_id
    loadHandler.setLoadingState('list_nodes_available', true)
    useApi
      .requestV2(
        'get',
        `v1/organisations/${organisation_id}/nodes_available?fields=provider,provider_identifier,address,place,object_id,medium`
      )
      .then((response) => {
        availableNodes.value = response.data
        loadHandler.setLoadingState('list_nodes_available', false)
      })
  }

  function unassignNode(id) {
    loadHandler.setLoadingState('unassign_node', true)
    const organisation_id = data.value.object_id
    useApi.requestV2('delete', `v1/organisations/${organisation_id}/nodes?node_ids=${id}`).then(() => {
      getNodes(organisation_id)
      loadHandler.setLoadingState('unassign_node', false)
    })
  }

  function closeAdd() {
    showAdd.value = false
  }

  function assignNodes() {
    loadHandler.setLoadingState('assign_nodes', true)
    const ids = Object.keys(selectedNodes.value)
    const organisation_id = data.value.object_id

    useApi.requestV2('post', `v1/organisations/${data.value.object_id}/nodes`, ids).then((response) => {
      availableNodes.value = response.data
      closeAdd()
      getNodes(organisation_id)
      loadHandler.setLoadingState('assign_nodes', false)
    })
  }

  function syncSelection(payload) {
    selectedNodes.value = payload.value
  }

  const detailFields = ref([
    {
      label: 'Name',
      key: 'name',
      type: 'string',
    },
    {
      label: 'Demo',
      key: 'demo',
      type: 'toggle',
    },
    {
      label: 'Prices',
      key: 'prices',
      type: 'toggle',
    },
    {
      label: 'Electricity',
      key: 'electricity',
      type: 'toggle',
    },
    {
      label: 'Gas',
      key: 'gas',
      type: 'toggle',
    },
    {
      label: 'Water',
      key: 'water',
      type: 'toggle',
    },
    {
      label: 'Heat',
      key: 'heat',
      type: 'toggle',
    },
    {
      label: 'HeatCost',
      key: 'heat_cost',
      type: 'toggle',
    },
    {
      label: 'Date created',
      key: 'date_created',
      type: 'date',
      disabled: true,
    },
    {
      label: 'Date edited',
      key: 'date_edited',
      type: 'date',
      disabled: true,
    },
  ])

  function unassigUser(payload) {
    loadHandler.setLoadingState('unassign_users', true)
    const organisation_id = data.value.object_id

    useApi
      .requestV2('delete', `v1/organisations/${organisation_id}/users?ids=${payload}`)
      .then(() => {
        getUsers(organisation_id)
        loadHandler.setLoadingState('unassign_users', false)
      })
      .catch(() => {
        loadHandler.setLoadingState('unassign_users', false)
      })
  }

  function assignUsers() {
    loadHandler.setLoadingState('assign_users', true)
    const ids = Object.keys(selectedUsers.value)
    const organisation_id = data.value.object_id

    useApi
      .requestV2('post', `v1/organisations/${organisation_id}/users`, ids)
      .then((response) => {
        availableNodes.value = response.data
        closeAssignUser()
        getUsers(organisation_id)
        loadHandler.setLoadingState('assign_users', false)
      })
      .catch(() => {
        loadHandler.setLoadingState('assign_users', false)
      })
  }

  function openAssignUser() {
    loadHandler.setLoadingState('list_users_available', true)
    showAssignUser.value = true
    const organisation_id = data.value.object_id

    useApi.requestV2('get', `v1/organisations/${organisation_id}/users_available`).then((response) => {
      usersAvailable.value = response.data
      loadHandler.setLoadingState('list_users_available', false)
    })
  }

  function closeAssignUser() {
    showAssignUser.value = false
    selectedUsers.value = []
  }

  function syncSelectionUsers(payload) {
    selectedUsers.value = payload.value
  }

  function openAddNodeType() {
    showAddNodeType.value = true
    addNodeTypeForm.create({
      type: 'add',
      fields: nodeTypeFields.value,
      data: {},
    })
  }

  function closeAddNodeType() {
    showAddNodeType.value = false
  }

  function addNodeType() {
    loadHandler.setLoadingState('add_node_type', true)
    const payload = addNodeTypeForm.data.value
    const organisation_id = data.value.object_id

    useApi
      .requestV2('post', `v1/organisations/${organisation_id}/node_types`, payload)
      .then(() => {
        showAddNodeType.value = false
        loadHandler.setLoadingState('add_node_type', false)
        getNodeTypes(organisation_id)
      })
      .catch(() => {
        useToast.addToast('Node type toevoegen mislukt', 'danger')
        loadHandler.setLoadingState('add_node_type', false)
      })
  }

  function openEditNodeType(data) {
    showEditNodeType.value = true
    editNodeTypeForm.create({
      type: 'edit',
      fields: nodeTypeFields.value,
      data: data,
    })
  }

  function closeEditNodeType() {
    showEditNodeType.value = false
  }

  function editNodeType() {
    loadHandler.setLoadingState('edit_node_type', true)
    const payload = editNodeTypeForm.data.value
    const organisation_id = data.value.object_id

    useApi
      .requestV2('patch', `v1/organisations/${organisation_id}/node_types/${payload.object_id}`, payload)
      .then(() => {
        showEditNodeType.value = false
        loadHandler.setLoadingState('edit_node_type', false)
        getNodeTypes(organisation_id)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function deleteNodeType(id) {
    loadHandler.setLoadingState('delete_node_type', true)
    const organisation_id = data.value.object_id

    useApi
      .requestV2('delete', `v1/organisations/${organisation_id}/node_types/${id}`)
      .then(() => {
        getNodeTypes(organisation_id)
        loadHandler.setLoadingState('delete_node_type', false)
      })
      .catch(() => {
        loadHandler.setLoadingState('delete_node_type', false)
      })
  }

  const nodeFields = ref([
    {
      label: 'Object ID',
      key: 'object_id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Identifier',
      key: 'provider_identifier',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Medium',
      key: 'medium',
      type: 'string',
      searchable: true,
      component: 'pillMedium',
    },
    {
      label: 'Address',
      key: 'address',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Place',
      key: 'place',
      type: 'string',
      searchable: true,
    },
  ])

  const nodesAvailablefields = ref([
    {
      label: 'Provider',
      key: 'provider',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Medium',
      key: 'medium',
      type: 'string',
      searchable: true,
      component: 'pillMedium',
    },
    {
      label: 'Identifier',
      key: 'provider_identifier',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Address',
      key: 'address',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Place',
      key: 'place',
      type: 'string',
      searchable: true,
    },
  ])

  const userFields = ref([
    {
      label: 'Object ID',
      key: 'object_id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Email',
      key: 'email',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Auth0 id',
      key: 'auth0_user_id',
      type: 'string',
      searchable: true,
    },
  ])

  const nodeTypeFields = ref([
    {
      label: 'Object ID',
      key: 'object_id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Name',
      key: 'name',
      type: 'string',
      searchable: true,
    },
    {
      label: 'Count usage',
      key: 'count_usage',
      type: 'toggle',
      default: false,
      component: 'pillTrueFalse',
    },
    {
      label: 'Count return',
      key: 'count_return',
      type: 'toggle',
      default: false,
      component: 'pillTrueFalse',
    },
    {
      label: 'Count generation',
      key: 'count_generation',
      type: 'toggle',
      default: false,
      component: 'pillTrueFalse',
    },
  ])

  const logFields = ref([
    {
      label: 'Object ID',
      key: 'id',
      type: 'id',
      table: false,
      add: false,
      edit: false,
    },
    {
      label: 'Datum',
      key: 'date_created',
      type: 'date',
      add: false,
      edit: false,
      filter: 'dateTimeFromEpoch',
    },
    {
      label: 'Content',
      key: 'content',
      type: 'string',
      searchable: true,
    },
  ])

  return {
    getDetails,
    data,
    detailFields,
    editForm,
    loadHandler,
    edit,
    nodeFields,
    currentNodes,
    availableNodes,
    openAdd,
    unassignNode,
    showAdd,
    closeAdd,
    assignNodes,
    nodesAvailablefields,
    syncSelection,
    selectedNodes,
    userFields,
    users,
    unassigUser,
    assignUsers,
    openAssignUser,
    closeAssignUser,
    showAssignUser,
    usersAvailable,
    syncSelectionUsers,
    nodeTypeFields,
    nodeTypes,
    openAddNodeType,
    closeAddNodeType,
    addNodeType,
    addNodeTypeForm,
    showAddNodeType,
    openEditNodeType,
    closeEditNodeType,
    editNodeType,
    editNodeTypeForm,
    showEditNodeType,
    logs,
    logFields,
    deleteNodeType,
  }
}
