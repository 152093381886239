import { ref, watch, computed } from 'vue'
import apiHandler from '@/use/apiHandler'
import loadingHandler from '@/use/loadingHandler'
import formHandler from '@/use/formHandler'
import selectValues from '@/use/selects/values'
import timeHandler from '@/plugins/base/components/time/timeHandler'
import chartOptionshandler from '@/use/chartOptionsHandler'

export default () => {
  const data = ref({})
  const useApi = apiHandler()
  const loadHandler = loadingHandler()
  const editForm = formHandler()
  const useTime = timeHandler()
  const chart_data_usage = ref([])
  const chart_data_production_return = ref([])
  const useChartOptions = chartOptionshandler()

  function getDetails(payload) {
    loadHandler.setLoadingState('get_details', true)

    useApi.requestV2('get', `v1/nodes/${payload}`, payload).then((response) => {
      data.value = response.data

      editForm.create({
        type: 'edit',
        fields: detailFields.value,
        data: response.data,
      })

      const payload = {
        ids: [data.value.object_id],
        interval: useTime.interval.value,
        start_date: useTime.startDate.value.getTime(),
        end_date: useTime.endDate.value.getTime(),
      }

      getSummary(payload)

      loadHandler.setLoadingState('get_details', false)
    })
  }

  function editNode() {
    loadHandler.setLoadingState('edit_node', true)
    const payload = editForm.getData()

    console.log(payload)

    const node_id = payload.object_id

    useApi.requestV2('patch', `v1/nodes/${node_id}`, payload).then(() => {
      loadHandler.setLoadingState('edit_node', false)
    })
  }

  watch([() => useTime.value, () => useTime.startDate.value, () => useTime.endDate.value], async () => {
    const payload = {
      ids: [data.value.object_id],
      interval: useTime.interval.value,
      start_date: useTime.startDate.value.getTime(),
      end_date: useTime.endDate.value.getTime(),
    }
    getSummary(payload)
  })

  function getSummary(payload) {
    loadHandler.setLoadingState('get_summary', true)

    // parse payload to query string
    const queryString = useApi.toQueryString(payload)

    useApi.requestV2('get', 'v1/nodes/data/summary?' + queryString).then((response) => {
      const data = response.data

      const { usage, production } = data

      // parase timestamps
      const formattedUsage = usage.map(([datetime, value]) => {
        const formattedDate = useTime.labelSelector(payload.interval, new Date(datetime))
        return [formattedDate, value]
      })

      const formattedProduction = production.map(([datetime, value]) => {
        const formattedDate = useTime.labelSelector(payload.interval, new Date(datetime))
        return [formattedDate, value]
      })

      chart_data_usage.value = formattedUsage
      chart_data_production_return.value = formattedProduction

      loadHandler.setLoadingState('get_summary', false)
    })
  }

  const chart_options_usage = computed(() => {
    if (data.value) {
      return useChartOptions.getOptions(data.value.medium, chart_data_usage.value, 'Usage')
    }
    return useChartOptions.getOptions(data.value.medium, {})
  })

  const chart_options_return = computed(() => {
    if (data.value) {
      return useChartOptions.getOptions(data.value.medium, chart_data_production_return.value, 'Return')
    }
    return useChartOptions.getOptions(data.value.medium, {})
  })

  const detailFields = ref([
    {
      label: 'Provider',
      key: 'provider',
      type: 'string',
      disabled: true,
    },
    {
      label: 'Identifier',
      key: 'provider_identifier',
      type: 'string',
      disabled: true,
    },
    {
      label: 'Meterid',
      key: 'meterid',
      type: 'string',
    },
    {
      label: 'Description',
      key: 'description',
      type: 'string',
    },
    {
      label: 'Address',
      key: 'address',
      type: 'string',
    },
    {
      label: 'Zip',
      key: 'zip',
      type: 'string',
    },
    {
      label: 'Place',
      key: 'place',
      type: 'string',
    },
    {
      label: 'Medium',
      key: 'medium',
      type: 'select',
      options: selectValues.medium,
      translate_value: true,
    },
    {
      label: 'Size',
      key: 'size',
      type: 'select',
      options: selectValues.size,
      translate_value: true,
    },
    {
      label: 'Residential',
      key: 'residential',
      type: 'toggle',
      table: false,
      options: selectValues.jaNee,
      dependent_show_values: ['Electricity'],
      dependent_show_field_key: 'medium',
      translate_value: true,
    },
    {
      label: 'Discount',
      key: 'discount',
      type: 'select',
      table: false,
      options: selectValues.jaNee,
      dependent_show_values: ['Gas'],
      dependent_show_field_key: 'medium',
      translate_value: true,
    },
    {
      label: 'Model',
      key: 'model',
      type: 'select',
      options: selectValues.model,
      dependent_show_values: ['B One Middleware'],
      dependent_show_field_key: 'provider',
      dependent_show_field_type: 'select',
    },
  ])

  return {
    getDetails,
    data,
    detailFields,
    editForm,
    loadHandler,
    editNode,
    chart_data_usage,
    chart_data_production_return,
    chart_options_usage,
    chart_options_return,
  }
}
